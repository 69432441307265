<template>
  <card-info-component
    :title="$t('pages.permission.vacations.days_rejected')"
    :value="value"
  ></card-info-component>
</template>

<script>
import { CardInfoComponent } from "@/pages/components";

export default {
  name: "DaysRejectedWidget",
  components: {
    CardInfoComponent,
  },
  data() {
    return {
      value: 0,
    };
  },
};
</script>

<style scoped></style>
